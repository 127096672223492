/* :root {
  --primary: #37a9e1;
  --primary-gradient: linear-gradient(white, #8aaae2 0%, #10648e 50%);
} */
 #workhistory {
  position: relative;
  z-index: 1;
 
  background:url('../assets/contactbg.jpg') no-repeat center center;
  background-size: cover;
}


.work-header h1 {
  margin-bottom: 50px;
  text-align: left;
  color:#D4F21B!important;
position:relative;/* Position relative to the .section container */
line-height: 1.2;
font-size: 6.725rem;

}
.section-header hr {
  border: 1px solid #0d0a0a;
  width: 56px;
  
}


.bg-gradient_solid {
  background: var(--primary-gradient);
  color: #f7f6f6;
  padding: 48px 0;
}
.steps {
  position: relative;
  margin-top: 32px;
}
.steps::after {
  content: "";
  position: absolute;
  width: 2px;
  background-color: rgb(255, 255, 255);
  opacity: 4.4;
  top: 0;
  bottom: 0;
  left: 50%;
}

.steps .content p {
  color: #676767;
  font-size: 16px;
}

.steps .content h2 {
  font-weight: 600;
  font-size: 16px;
  color: #676767;
}

.steps-container {
  position: relative;
  background-color: inherit;
  width: calc(50% + 32px);
  
}

.steps-container .content {
  padding: 32px;
  background-color:#ffffff!important;
  position: relative;
  border-radius: 0px 0px 80px 0px;
  box-shadow: 0px 16px 27px rgba(121, 165, 242, 0.693);
  border: 2px solid black;
}


.steps .steps-container:nth-child(even) {
  left: calc(50% - 32px);
  flex-direction: row-reverse;
}

.steps-container {
  display: flex;
}

.steps .steps-container .date {
  font-weight: 900;
  font-size: 16px;
  color: #000000;
  border: 2px solid black;

  margin-bottom: 10px;
  width: 62px;
  height: 62px;
  background-color: #d4f21b;
  border-radius: 50%;
  flex-shrink: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 777;
}

.step-line {
  width: 40px;
  background-color: #fffefe;
  height: 5px;
  margin-top: 31px;
  opacity: 1.4;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .steps::after {
    left: 32px;
  }
  .steps-container {
    left: 0;
    flex-direction: row-reverse;
    width: auto;
    margin-bottom: 16px;
    border: 2px solid black;
  }
  .steps .steps-container:nth-child(even) {
    left: 0;
  }
}
