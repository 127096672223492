/* General styles */

@import url(https://fonts.googleapis.com/css?family=Bungee);

#landingpage {
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background: url('../assets/anzal1.png') no-repeat center center;
  background-size: cover;
  background-position: center;
}

.text-content {
  position: relative;
  z-index: 1;
  color: #FFF;
  padding-left: 2rem;
}

.profession {
  font-size: 1rem;
  opacity: 0.8;
  margin: 0 0 1rem 0;
  line-height: 1.5;
}

.animated-text {
  color: #D4F21B !important;
  font-family: 'Karla', sans-serif;
  font-weight: bold;
  text-align: left;
  font-size: 8.725rem;
  line-height: 1.2;
  position: relative;
}

.animated-text1 {
  font-family: 'Karla', sans-serif;
  font-size: 7.725rem;
  font-weight: 700;
  text-align: left;
  color: white;
  position: relative;
  line-height: 1.2;
}

h1 span {
  color: #FFF;
}

.animate__animated.animate__fadeInLeft,
.animate__animated.animate__fadeInRight {
  --animate-duration: 1s;
}

.custom-easing {
  transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
}

.about-page .content {
  margin-top: 20px;
}

section {
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

.horizontal-line {
  position: relative;
  top: 0;
  height: 5px;
  background-color: yellow;
  display: block;
  z-index: 0;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .section-heading {
    font-size: 2.5rem;
  }
  .horizontal-line {
    height: 2px;
    margin: 25px 0;
  }
}

@media (max-width: 1024px) {
  .horizontal-line {
    height: 2px;
    margin: 25px 0;
  }
}

@media (max-width: 992px) {
  .section-heading {
    font-size: 2.25rem;
  }
  .horizontal-line {
    height: 2px;
    margin: 20px 0;
  }
  .content {
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .section-heading {
    font-size: 2rem;
  }
  p, ul {
    font-size: 0.9rem;
  }
  .content {
    padding: 10px;
  }
  .horizontal-line {
    height: 2px;
    margin: 20px 0;
  }
    #landingpage {
    background-size: cover;
  }
}

@media (max-width: 576px) {
  .section-heading {
    font-size: 1.75rem;
  }
  p, ul {
    font-size: 0.85rem;
  }
  .content {
    padding: 5px;
  }
  .horizontal-line {
    height: 1px;
    margin: 15px 0;
  }
  #landingpage {
    background-size: cover;
  }
}

@media (max-width: 400px) {
  .section-heading {
    font-size: 1.5rem;
  }
  .horizontal-line {
    height: 1px;
  }
  .vertical-line {
    width: 1px;
    height: 20%;
    left: 0;
  }
  p, ul {
    font-size: 0.8rem;
  }
  .content {
    padding: 2px;
  }
  #landingpage {
    background-size: cover;
  }
}